import React from 'react';
import {NavLink} from "react-router-dom";

const activeStyle = {
    fontWeight: "bold"
};

export default class Navigation extends React.Component {
    changeClick(e){
        const menu = document.querySelector('.main-nav-list');
        menu.classList.remove('show');
        window.scrollTo(0, 0)
    }
    //to do mobilki będzie powyżej--Darek
    render() {
        return (
            <>
                <ul onClick={e => this.changeClick(e)} className="main-nav-list" id="menu">
                    <li><NavLink activeStyle={activeStyle} exact to="/">Strona Główna</NavLink></li>
                    <li><NavLink activeStyle={activeStyle} to="/Rentalpage">Wynajem</NavLink></li>
                    <li><NavLink activeStyle={activeStyle} to="/Renovationpage">Renowacje</NavLink></li>
                    <li><NavLink activeStyle={activeStyle} to="/Salespage">Sprzedaż</NavLink></li>
                    <li><NavLink activeStyle={activeStyle} to="/Winteringpage">Zimowanie</NavLink></li>
                    <li><NavLink activeStyle={activeStyle} to="/Partspage">Części</NavLink></li>
                    <li><NavLink activeStyle={activeStyle} to="/Aboutuspage">Kontakt</NavLink></li>
                    {/* do zrobienia Darek<li><NavLink activeStyle={activeStyle} to="/realizacje">Technologie</NavLink></li>*/}

                </ul>
            </>
        )
    }
};