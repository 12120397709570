import React from 'react';
/*import { Link } from "react-scroll";*/
import {ParallaxProvider} from 'react-scroll-parallax';

import Banner from "./MainComponents/Banner";
import Rental from "./MainComponents/Rental";
import Renovation from "./MainComponents/Renovation";
import Sales from "./MainComponents/Sales";
import Wintering from "./MainComponents/Wintering";
import Parts from "./MainComponents/Parts";
import About from "./MainComponents/About";


export default class Main extends React.Component {
    
    render() {
        return(
            <main>
                {/*<MainNavigation/>*/}
                <ParallaxProvider>
                    <Banner/>
                    <Rental/>
                    <Renovation/>
                    <Sales/>
                    <Wintering/>
                    <Parts/>
                    <About/>
                </ParallaxProvider>
            </main>
        )
    }
}

/*class MainNavigation extends React.Component {

    render() {
        return (
            <div className="main-link-navigation">
                <ul className="mainPage-nav-list" id="home-menu">
                    <Link
                        activeClass="active"
                        to="rental"
                        spy={true}
                        smooth={true}
                        offset={-242}
                        duration= {500}
                        className="main-nav-list-link"
                    >Wynajem</Link>
                    <Link
                        activeClass="active"
                        to="renovation"
                        spy={true}
                        smooth={true}
                        offset={-242}
                        duration= {800}
                        className="main-nav-list-link"
                    >Renowacje</Link>
                    <Link
                        activeClass="active"
                        to="sales"
                        spy={true}
                        smooth={true}
                        offset={-60}
                        duration= {1000}
                        className="main-nav-list-link"
                    >Sprzedaż</Link>
                    <Link
                        activeClass="active"
                        to="wintering"
                        spy={true}
                        smooth={true}
                        offset={-60}
                        duration= {1200}
                        className="main-nav-list-link"
                    >Zimowanie</Link>
                    <Link
                        activeClass="active"
                        to="parts"
                        spy={true}
                        smooth={true}
                        offset={-60}
                        duration= {1200}
                        className="main-nav-list-link"
                    >Części</Link>
                    <Link
                        activeClass="active"
                        to="about-us"
                        spy={true}
                        smooth={true}
                        offset={-60}
                        duration= {1200}
                        className="main-nav-list-link"
                    >O nas!</Link>
                    <Link
                        activeClass="active"
                        to="contact"
                        spy={true}
                        smooth={true}
                        offset={-60}
                        duration= {1200}
                        className="main-nav-list-link"
                    >Kontakt!</Link>
                </ul>
            </div>
        )
    }
};
*/