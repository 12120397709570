import React from 'react';
import ImageGallery from 'react-image-gallery';
import ph1 from "../assets/1d.jpg";
import ph2 from "../assets/2d.jpg";
import ph3 from "../assets/3d.jpg";
import ph4 from "../assets/4d.jpg";
import ph5 from "../assets/5d.jpg";
import ph6 from "../assets/6d.jpg";

import ph1m from "../assets/mobile/1m.jpg";
import ph2m from "../assets/mobile/2m.jpg";
import ph3m from "../assets/mobile/3m.jpg";
import ph4m from "../assets/mobile/4m.jpg";
import ph5m from "../assets/mobile/5m.jpg";
import ph6m from "../assets/mobile/6m.jpg";
import ph7m from "../assets/mobile/7m.jpg";
import ph8m from "../assets/mobile/8m.jpg";
import ph9m from "../assets/mobile/9m.jpg";
import ph10m from "../assets/mobile/10m.jpg";
import ph11m from "../assets/mobile/11m.jpg";

const images1 = [
    {
        original: ph1,
        thumbnail: ph1,
    },
    {
        original: ph2,
        thumbnail: ph2,
    },
    {
        original: ph3,
        thumbnail: ph3,
    },
    {
        original: ph4,
        thumbnail: ph4,
    },
    {
        original: ph5,
        thumbnail: ph5,
    },
    {
        original: ph6,
        thumbnail: ph6,
    },
];

const images2 = [
    {
        original: ph1m,
        thumbnail: ph1m,
    },
    {
        original: ph2m,
        thumbnail: ph2m,
    },
    {
        original: ph3m,
        thumbnail: ph3m,
    },
    {
        original: ph4m,
        thumbnail: ph4m,
    },
    {
        original: ph5m,
        thumbnail: ph5m,
    },
    {
        original: ph6m,
        thumbnail: ph6m,
    },
    {
        original: ph7m,
        thumbnail: ph7m,
    },
    {
        original: ph8m,
        thumbnail: ph8m,
    },
    {
        original: ph9m,
        thumbnail: ph9m,
    },
    {
        original: ph10m,
        thumbnail: ph10m,
    },
    {
        original: ph11m,
        thumbnail: ph11m,
    },
];

const properties = {
    thumbnailPosition: "bottom",
};
export default class Renovationpage extends React.Component {
    render() {
        return(
            <div className="renovation-gallery-container">
                <div className="renovation-gallery-desktop">
                    <ImageGallery {...properties} items={images1} />
                </div>
                <div className="renovation-gallery-mobile" >
                    <ImageGallery {...properties} items={images2} />
                </div>

            </div>
        )
    }
}