import React from 'react';
import {ParallaxProvider} from 'react-scroll-parallax';
import {ParallaxBanner} from 'react-scroll-parallax';
import Image4a from "../assets/Wintering2.jpg";
import ScrollAnimation from 'react-animate-on-scroll';

export default class Winteringpage extends React.Component {
    render() {
        return(
            <ParallaxProvider>
            <ScrollAnimation duration='2' animateIn='bounceInLeft'
            animateOut='bounceOutRight'>
             <div className="wintering-container" id="Winteringpage">
                <div className="wintering-header">
                            <h1>Zimowanie</h1>
                </div>
                <div className="winteringpage">
                     <div className="wintering-right">
                        <div className="wintering-text2">
                            <p>Oferujemy możliwość przechowywania przyczepy lub kampera na ogrodzonym placu.</p>
                            <p className="wintering-text">Ozonowanie przed sezonem gratis!</p>
                            <p>Czyszczenie przyczepy wewnątrz i z zewnątrz w pakiecie.</p>
                            <p className="wintering-text">CENA: 120 zł/m-c.</p>
                            <a href="mailto:camperowcy@gmail.com">
                            <button className="btn-winteringpage one">Skontaktuj się z nami &nbsp; &nbsp; &#8594;</button>
                            </a>
                        </div>
                     </div>
                     <div className="wintering-left">
                        <div className="wintering-row">
                            <ParallaxBanner
                                className="winteringpage-parallax"
                                layers={[
                                    {image: Image4a, amount: 0.11}
                                    ]}
                                style={{height: '100%', width: '100%'}}
                            >
                            </ParallaxBanner>
                        </div> 
                     </div>

                </div>
             </div>
            </ScrollAnimation>
            </ParallaxProvider>
        )
    }
}