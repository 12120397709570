import React from 'react';
import ImageGallery from 'react-image-gallery';

import ba1 from "../assets/1BAD.jpeg";
import ba2 from "../assets/2BAD.jpeg";
import ba3 from "../assets/3BAD.jpeg";
import ba4 from "../assets/4BAD.jpeg";

import bam1 from "../assets/mobile/1BAM.jpeg";
import bam2 from "../assets/mobile/2BAM.jpeg";
import bam3 from "../assets/mobile/3BAM.jpeg";
import bam4 from "../assets/mobile/4BAM.jpeg";
import bam5 from "../assets/mobile/5BAM.jpeg";
import bam6 from "../assets/mobile/6BAM.jpeg";

const imagesrental2 = [
    {
        original: ba1,
        thumbnail: ba1,
    },
    {
        original: ba2,
        thumbnail: ba2,
    },
    {
        original: ba3,
        thumbnail: ba3,
    },
    {
        original: ba4,
        thumbnail: ba4,
    },  
];

const imagesrental2m = [
    {
        original: bam1,
        thumbnail: bam1,
    },
    {
        original: bam2,
        thumbnail: bam2,
    },
    {
        original: bam3,
        thumbnail: bam3,
    },
    {
        original: bam4,
        thumbnail: bam4,
    },
    {
        original: bam5,
        thumbnail: bam5,
    },
    {
        original: bam6,
        thumbnail: bam6,
    },
];

const properties = {
    thumbnailPosition: "bottom",
    autoPlay: false,
};


export default class Item3 extends React.Component {
    totopClick3(e){
        window.scrollTo(0, 0)
    }
    render() {
        return(
            <div className="page-main">
                <div className="container">    
                    <div className="row">
                        <div className="col-8-12">
                            <div className="box">
                                <div className="box-image"/>
                                <h1 className="box-title">Burstner Averso 470TS - 2011</h1>
                                <div className="box-content-gallery">
                                    <div className="image-gallery-rental-desktop">
                                        <ImageGallery {...properties} items={imagesrental2} />
                                    </div>
                                    <div className="image-gallery-rental-mobile">
                                        <ImageGallery {...properties} items={imagesrental2m} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4-12">
                            <div className="box colorbox">
                                <div className="box-image"/>
                                <h2 className="box-title1">CENNIK</h2>
                                    <div className="box-content1">
                                        <p className="rental-text-paragraf">Koszt za dobę:</p>
                                        <p>Sezon niski: 1 październik – 23 kwiecień (minimalny wynajem: 3 dni) - 140 zł</p>
                                        <p>Sezon średni: 10 maj – 11 czerwiec, 13 wrzesień – 30 wrzesień (minimalny wynajem: 5 dni) - 160 zł</p>
                                        <p>Sezon wysoki: 24 kwiecień – 9 maj, 12 czerwiec – 12 wrzesień (minimalny wynajem: 7 dni) - 210 zł</p>
                                        <p>Możliwość dowiezienia przyczepy we wskazane miejsce! (Koszt wyceniany indywidualnie)</p>
                                        <p className="rental-text-paragraf">Rabaty:</p>
                                        <p>Wynajem powyżej 14 dni – opłata serwisowa gratis.</p>
                                        <p>Wynajem powyżej 21 dni – rabat 5%</p>
                                        <p>Opłata serwisowa 250 zł - obejmuje mycie przyczepy od wewnątrz i z zewnątrz, pełną butlę z gazem, kontrolę i serwis urządzeń oraz pakiet toaletowy na około 12 dni toalety: kaseta z WC jest czyszczona i uzupełniona o chemię na ok. 4 doby, wyposażona dodatkowo znajdują się: 3 rolki papieru toaletowego – rozpuszczalnego, koncentrat płynu do spłuczki, 3 kostki rozpuszczalne i zapachowe do kasety WC.</p>
                                        <p>Kaucja zwrotna: 2000 PLN</p>
                                        <p>Przy rezerwacji pobieramy 30% kosztu wynajmu.</p>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8-12">
                            <div className="box1">
                                <h1 className="box-title">OPIS</h1>
                                <div className="box-content">  
                                    <div className="rental-text">
                                        <p className="rental-text-paragraf">Marka i model:  Burstner Averso 470TS 2011</p>
                                        <p>Przyczepa kempingowa Burstner Averso 470TS z 2011 roku.</p>
                                        <p>Przyczepa starsza, ale bardzo zadbana i wygodna,</p>
                                        <p>Miejsca do spania dla osób dorosłych: 2 (łóżko małżeńskie 145x200)</p>
                                        <p>Miejsca do spania dla dzieci 2 (łóżko rozkładane z kanap kuchennych - 100x170)</p>
                                        <p  className="rental-text-paragraf">Dopuszczalna masa całkowita: 1250 kg</p>
                                        <h2 className="rental-text-paragraf">Wyposażenie:</h2>
                                        <p>- Klimatyzator przenośny</p>
                                        <p>- Bojler elektryczny TRUMA</p>
                                        <p>- Zbiornik na wodę brudną na kółkach</p>
                                        <p>- oświetlenie Ambiente</p>
                                        <p>- Moskitiery</p>
                                        
                                    </div>
                        
                                </div>
                            </div>
                        </div>
                        <div className="col-4-12">
                            <div className="box colorbox2">
                                <div className="box-image"/>
                                <h2 className="box-title2">DOKUMENTY DO POBRANIA</h2>
                                <div className="box-content">
                                    <p>
                                        <a className="rental-files" href="/Regulamin wynajmu przyczep.pdf">Pobierz "Regulamin wynajmu przyczep"</a>
                                    </p>
                                    <p>
                                        <a className="rental-files" href="/Skrocona instrukcja obslugi przyczepy kempingowej.pdf">Pobierz "Skrócona instrukcja obsługi przyczepy kempingowej"</a>
                                    </p>
                                    <p>
                                        <a className="rental-files" href="/Umowa wynajmu przyczep.pdf">Pobierz "Umowa wynajmu przyczep"</a>
                                    </p>
                                    <p>
                                        <a className="rental-files" href="/CHECKLISTA.pdf">Pobierz "CHECK-LISTA"</a>
                                    </p>
                                    <p>
                                        <a className="rental-files" href="/Cennik.pdf">Pobierz "Cennik i wyposażenie"</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a className="buttonlink" href="#/Item4">
	                <button onClick={e => this.totopClick3(e)} className="btn-navigation two bottombutton">Następna &nbsp; &#8594;</button>
                </a>
                <a className="buttonlink" href="#/Item2">
	                <button onClick={e => this.totopClick3(e)} className="btn-navigation two bottombuttonback">&#8592; &nbsp; Powrót &nbsp;</button>
                </a>
            </div>
        )
    }
}