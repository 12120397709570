import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

export default class Wintering extends React.Component {
    render() {
        return(
            <ScrollAnimation duration='2' animateIn='bounceInRight'
            animateOut='bounceOutLeft'>
            <div className="main-generic-container">
                
                <div className="main-generic">
                    <div className="main-wintering-textpanel">
                        <div className="main-generic-header">
                            <h1>Zimowanie</h1>
                        </div>
                        <div className="main-generic-text">
                            <p>Oferujemy możliwość przechowywania przyczepy lub kampera na ogrodzonym placu.</p>
                                <p className= "main-generic-bold-text">Ozonowanie przed sezonem gratis!</p>
                                <p>Czyszczenie przyczepy wewnątrz i z zewnątrz w pakiecie.</p>
                                <p>Cena usługi: 120 zł/m-c</p>
                            </div>
                    </div>
                    <div className="main-generic-photo">
                    <div className="main-wintering-image"></div> 
                    </div>
                </div>
            </div>
            </ScrollAnimation>
        )
    }
}