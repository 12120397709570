import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

export default class Sales extends React.Component {  
totopClick(e){
    window.scrollTo(0, 0)
}
    render() {
        return(
            <ScrollAnimation duration='2' animateIn='bounceInLeft'
            animateOut='bounceOutRight'>
            <div className="main-generic-container">
                
                <div className="main-generic">
                    <div className="main-generic-photo">
                    <div className="main-sales-image"></div>
                    </div>

                    <div className="main-generic-textpanel">
                        <div className="main-generic-header">
                            <h1>Sprzedaż</h1>
                        </div>
                        <div className="main-generic-text">
                            <p>Sprzedajemy przyczepy używane różnej klasy. Każda z
                            nich zostaje u nas dokładnie sprawdzona, uszczelniona i
                            jeśli tego wymaga naprawiona.</p>
                            <ul className="main-generic-list">
                                <li>Sprawdzone przyczepy</li>
                                <li>Serwis</li>
                                <li>Szczelność</li>
                                <li>Klimatyzacja</li>
                                <li>Przyczepy po remoncie lub odświeżeniu</li>
                            </ul>
                            <a className="buttonlink" href="#/Salespage">
                             <button onClick={e => this.totopClick(e)} className="btn-generic one">Sprawdź ofertę &nbsp; &nbsp; &#8594;</button>
                            </a>
                            </div>
                    </div>
                </div>
            </div>
            </ScrollAnimation>
        )
    }
}