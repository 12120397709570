import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

export default class About extends React.Component {
    render() {
        return(
            <ScrollAnimation duration="2" animateIn='bounceInRight'
            animateOut='bounceOutLeft'>
            <div className="main-generic-container">
                
                <div className="main-generic">
                    <div className="main-generic-textpanel">
                        <div className="main-generic-header">
                            <h1>O nas i kontakt</h1>
                        </div>
                        <div className="main-generic-text">
                            
                            <ul className="main-generic-list">
                                <li>CamperOwcy.pl</li>
                                <li>mail: camperowcy@gmail.com</li>
                                <li>Tel: 509 684 222, 509 401 543</li>
                            </ul>
                            <p>Dane firmy:</p>
                            <p>No Limit Travel & Marideo s.c.</p>
                            <p>86-005 Białe Błota, ul. Czerska 8</p>
                            <p>NIP 953-261-41-09</p>
                            <p>Numer Konta: PKO BANK POLSKI – 44 1440 1215 0000 0000 1198 1078</p>
                            <p>Jesteśmy rodzinnym przedsiębiorstwem, które wywodzi się z biura podróży.
                            Nasza pasja skłoniła nas do skupienia się na indywidualnych formach swobodnego podróżowania - bezpiecznego i niezależnego.</p>
                            <p>Zapraszamy!</p>
                            </div>
                    </div>
                    <div className="main-generic-photo">
                    <div className="main-aboutus2-image"></div>
                    </div>
                </div>
            </div>
            </ScrollAnimation>
        )
    }
}