import React from 'react';
import {ParallaxProvider} from 'react-scroll-parallax';
import {ParallaxBanner} from 'react-scroll-parallax';
import Image5a from "../assets/Aboutus.png";
import ScrollAnimation from 'react-animate-on-scroll';

export default class Aboutuspage extends React.Component {
    render() {
        return(
            <ParallaxProvider>
            <ScrollAnimation duration='2' animateIn='bounceInLeft'
            animateOut='bounceOutRight'>
             <div className="aboutus-container">
                <div className="wintering-header">
                            <h1>O nas i kontakt</h1>
                </div>
                <div className="winteringpage">
                     <div className="aboutus-right">
                        <div className="aboutus-text">
                            <p>CamperOwcy.pl</p>
                            <p>Mail: camperowcy@gmail.com</p>
                            <p>Tel. 509 684 222</p>
                            <p>Tel. 509 401 543</p>
                            <p>Dane firmy:</p>
                            <p>No Limit Travel & Marideo s.c.</p>
                            <p>86-005 Białe Błota, ul. Czerska 8</p>
                            <p>NIP: 953-261-41-09</p>
                            <p>Numer Konta:</p>
                            <p>PKO BANK POLSKI – 44 1440 1215 0000 0000 1198 1078</p>
                            <p>Jesteśmy rodzinnym przedsiębiorstwem które wywodzi się z biura podróży.</p>
                            <p>Nasza pasja skłoniła nas do skupienia się na
                            indywidualnych formach swobodnego podróżowania -
                            bezpiecznego i niezależnego. Zapraszamy!</p>
                            <a href="mailto:camperowcy@gmail.com">
                            <button className="btn-aboutus one">Skontaktuj się z nami &nbsp; &nbsp; &#8594;</button>
                            </a>
                        </div>
                     </div>
                     <div className="aboutus-left">
                        <div className="wintering-row">
                            <ParallaxBanner
                                className="winteringpage-parallax"
                                layers={[
                                    {image: Image5a, amount: 0.11}
                                    ]}
                                style={{height: '100%', width: '100%'}}
                            >
                            </ParallaxBanner>
                        </div> 
                     </div>

                </div>
             </div>
            </ScrollAnimation>
            </ParallaxProvider>
        )
    }
}