import React from 'react';
import ImageGallery from 'react-image-gallery';
import phs1 from "../assets/JB1995_2.jpg";
import phs2 from "../assets/JB1995_3.jpg";
import phs3 from "../assets/JB1995.jpg";

const images = [
    {
        original: phs1,
        thumbnail: phs1,
    },
    {
        original: phs2,
        thumbnail: phs2,
    },
    {
        original: phs3,
        thumbnail: phs3,
    },
];

const properties = {
    thumbnailPosition: "bottom",
    autoPlay: true,
    slideDuration: 800,
};


export default class Salespage extends React.Component {
    render() {
        return(
            <>
             <div className="sales-container" id="salespage">
                <div className="sales-header">
                            <h1>Sprzedaż</h1>
                </div>
                <div className="salespage">
                     <div className="sales-right">
                        <div className="sales-text">
                            <p className="sales-text-paragraf">Numer oferty: 01/2021</p>
                            <p>Marka: Abi Jubilee Equerry</p>
                            <p>Rok produkcji: 1995</p>
                            <p>DMC: 1100 kg.</p>
                            <p>Liczba miejsc: 3</p>
                            <p>Podłączenie: 13 pin</p>
                            <p>Przyczepa zarejestrowana, ubezpieczona (07.03.2021), z ważnym przeglądem (10.08.2021) - gotowa do drogi!</p>
                            <p className="sales-text-paragraf">Cena: Do negocjacji po obejrzeniu.</p>
                            
                        </div>
                     </div>
                     <div className="sales-left">
                        <div className="sales-row">
                            <div className="sales-photo1">
                                <p className="photo-text">SPRZEDANE</p>
                            </div>
                        </div> 
                     </div>
                </div>
             </div>
             <div className="sales-container2" id="salespage">
                
                <div className="salespage">
                <div className="sales-left">
                        <div className="sales-row">
                         <div className="sales-gallery-container">
                            <div id="image-gallery-sales">
                                <ImageGallery {...properties} items={images} />
                            </div>
                         </div>
                        </div> 
                     </div>
                     <div className="sales-right">
                        <div className="sales-text2">
                            <p>Wyjątkowa przyczepa kempingowa, brytyjskiej produkcji - Abi Jubilee Equerry z 1995 roku.</p>
                            <p>Przyczepa ma wielkie łóżko, spokojnie dla 2/3 osób lub nawet małżeństwa z dwójką dzieci.</p>
                            <p>Posiada kompletny przedsionek!</p>
                            <p>Wnętrze jest po całkowitym remoncie i ma niepowtarzalny marinistyczny charakter!</p>
                            <p>Wszystkie materace i tkaniny są nowe!</p>
                            <p>W przedniej części wypoczynkowej są dwie długie kanapy i składany stolik.</p>
                            <p>Tylna cześć to łazienka z toaletą, prysznicem, umywalką i dużą, dwudrzwiową szafą.</p>
                            <p>Przyczepa ogrzewana jest piecykiem gazowym z nadmuchem na cała przyczepę.</p>
                            <p>Kuchenka gazowa ma trzy palniki plus długi palnik i podgrzewacz. Osobno jest także piekarnik gazowy.</p>
                            <p>Lodówka gazowo-elektryczna.</p>
                            <p>Ogrzewanie wody gazowo-elektryczne.</p>
                            <p>W oknach bocznych są rolety i moskitiery.</p>
                            <p>Na wyposażeniu zbiornik na czystą wodę z kranikiem, przedłużacz, koło zapasowe, mała butla gazowa oraz przedsionek.</p>
                            <p>Ogrzewanie wody gazowo-elektryczne.</p>
                        </div>
                     </div>
                     
                </div>
             </div>
             </>
        )
    }
}