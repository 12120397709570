import React from 'react';
import ImageGallery from 'react-image-gallery';

import ka1 from "../assets/FK1.jpg";
import ka2 from "../assets/FK2.jpg";
import ka3 from "../assets/FK3.jpg";
import ka4 from "../assets/FK4.jpg";
import ka5 from "../assets/FK5.jpg";
import ka6 from "../assets/FK6.jpg";
import ka7 from "../assets/FK7.jpg";
import ka8 from "../assets/FK8.jpg";
import ka9 from "../assets/FK9.jpg";
import ka10 from "../assets/FK10.jpg";

import kam1 from "../assets/mobile/FKM1.jpg";
import kam2 from "../assets/mobile/FKM2.jpg";
import kam3 from "../assets/mobile/FKM3.jpg";
import kam4 from "../assets/mobile/FKM4.jpg";
import kam5 from "../assets/mobile/FKM5.jpg";
import kam6 from "../assets/mobile/FKM6.jpg";
import kam7 from "../assets/mobile/FKM7.jpg";
import kam8 from "../assets/mobile/FKM8.jpg";
import kam9 from "../assets/mobile/FKM9.jpg";
import kam10 from "../assets/mobile/FKM10.jpg";
import kam11 from "../assets/mobile/FKM11.jpg";

const imagesrentalka = [
    {
        original: ka1,
        thumbnail: ka1,
    },
    {
        original: ka2,
        thumbnail: ka2,
    },
    {
        original: ka3,
        thumbnail: ka3,
    },
    {
        original: ka4,
        thumbnail: ka4,
    },  
    {
        original: ka5,
        thumbnail: ka5,
    }, 
    {
        original: ka6,
        thumbnail: ka6,
    }, 
    {
        original: ka7,
        thumbnail: ka7,
    }, 
    {
        original: ka8,
        thumbnail: ka8,
    }, 
    {
        original: ka9,
        thumbnail: ka9,
    }, 
    {
        original: ka10,
        thumbnail: ka10,
    }, 
];

const imagesrentalkam = [
    {
        original: kam1,
        thumbnail: kam1,
    },
    {
        original: kam2,
        thumbnail: kam2,
    },
    {
        original: kam3,
        thumbnail: kam3,
    },
    {
        original: kam4,
        thumbnail: kam4,
    },
    {
        original: kam5,
        thumbnail: kam5,
    },
    {
        original: kam6,
        thumbnail: kam6,
    },
    {
        original: kam7,
        thumbnail: kam7,
    },
    {
        original: kam8,
        thumbnail: kam8,
    },
    {
        original: kam9,
        thumbnail: kam9,
    },
    {
        original: kam10,
        thumbnail: kam10,
    },
    {
        original: kam11,
        thumbnail: kam11,
    },

];

const properties = {
    thumbnailPosition: "bottom",
    autoPlay: false,
};


export default class Item4 extends React.Component {
    totopClick3(e){
        window.scrollTo(0, 0)
    }
    render() {
        return(
            <div className="page-main">
                <div className="container">    
                    <div className="row">
                        <div className="col-8-12">
                            <div className="box">
                                <div className="box-image"/>
                                <h1 className="box-title">FORD CHAUSSON 627GA VIP PREMIUM - 2021</h1>
                                <div className="box-content-gallery">
                                    <div className="image-gallery-rental-desktop">
                                        <ImageGallery {...properties} items={imagesrentalka} />
                                    </div>
                                    <div className="image-gallery-rental-mobile">
                                        <ImageGallery {...properties} items={imagesrentalkam} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4-12">
                            <div className="box colorbox">
                                <div className="box-image"/>
                                <h2 className="box-title1">CENNIK</h2>
                                    <div className="box-content1">
                                        <p className="rental-text-paragraf">Koszt za dobę:</p>
                                        <p>Sezon niski: 1 październik – 15 kwiecień (minimalny wynajem: 3 dni) - 550 zł</p>
                                        <p>Sezon średni: 9 maj – 10 czerwiec, 13 wrzesień – 30 wrzesień (minimalny wynajem: 5 dni) - 650 zł</p>
                                        <p>Sezon wysoki: 16 kwiecień – 8 maj, 11 czerwiec – 12 wrzesień (minimalny wynajem: 7 dni) - 750 zł</p>
                                    
                                        <p className="rental-text-paragraf">Rabaty:</p>
                                        <p>Wynajem powyżej 14 dni – opłata serwisowa gratis.</p>
                                        <p>Wynajem powyżej 21 dni – rabat 5%</p>
                                        <p>Opłata serwisowa 250 zł - obejmuje mycie kampera od wewnątrz i z zewnątrz, pełną butlę z gazem, kontrolę i serwis urządzeń oraz pakiet toaletowy na około 12 dni toalety: kaseta z WC jest czyszczona i uzupełniona o chemię na ok. 4 doby, wyposażona dodatkowo znajdują się: 3 rolki papieru toaletowego – rozpuszczalnego, koncentrat płynu do spłuczki, 3 kostki rozpuszczalne i zapachowe do kasety WC.</p>
                                        <p>Kaucja zwrotna: 4000 PLN</p>
                                        <p>Przy rezerwacji pobieramy 30% kosztu wynajmu.</p>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8-12">
                            <div className="box1">
                                <h1 className="box-title">OPIS</h1>
                                <div className="box-content">  
                                    <div className="rental-text">
                                        <p className="rental-text-paragraf">Marka i model: FORD CHAUSSON ROCZNIK 2021 MODEL 627GA VIP PREMIUM</p>
                                        <p>Do wynajęcia super wyposażony kamper z 2021 roku!!! Gotowy na zimowe wyjazdy!</p>
                                        <p>Silnik: 170 KM Diesel 2.0</p>
                                        <p>Długość całkowita: 6,99 m</p>
                                        <p>Wysokość całkowita: 2,92 m</p>
                                        <p>Miejsca do spania: 5-osobowy</p>
                                        <p>Dopuszczalna masa całkowita (DMC): 3500 kg (prawo jazdy kat. B)</p>
                                        <h2 className="rental-text-paragraf">Wyposażenie Kampera:</h2>
                                        <p>- Tylne łóżko 200 cm na 200 cm</p>
                                        <p>- Łóżko opuszczane 160 cm na 200 cm</p>
                                        <p>- Zastawa stołowa (sztućce, talerze, kubki)</p>
                                        <p>- Urządzenia i naczynia kuchenne (czajnik, patelnia, garnek)</p>
                                        <p>- Lodówka</p>
                                        <p>- Kuchenka gazowa</p>
                                        <p>- Butla gazowa</p>
                                        <p>- Meble (stół, krzesła)</p>
                                        <p>- Zbiorniki do wody</p>
                                        <p>- Rozbudowany tylny panel światła stop</p>
                                        <p>- Drzwi wejściowe Premium ze zintegrowanym koszem na śmieci i centralnym zamkiem</p>
                                        <p>- Materac Premium</p>
                                        <p>- Ekskluzywny panel wokół okna kuchennego z LED</p>
                                        <p>- Drewniany podest w łazience</p>
                                        <p>- Ekskluzywny panel przy drzwiach wejściowych z LED</p>
                                        <p>- Wykończenie Premium opuszczanego łóżka spod sufitu</p>
                                        <p>- Kamera cofania z wyświetlaczem w lusterku wstecznym</p>
                                        <p>- Rolety okien w szoferce REMIS</p>
                                        <p>- Zewnętrzne przyłącze gazowe (na szybkozłącze)</p>
                                        <p>- Zewnętrzne przyłącze prysznicowe (na szybkozłącze)</p>
                                        <p>- Obicie poduszek Ambience</p>
                                        <p>- Wentylator w oknie dachowym</p>
                                        <p>- Pakiet Zimowy</p>
                                        <h2 className="rental-text-paragraf">Wyposażenie dodatkowe:</h2>
                                        <p>- markiza FIAMMA F45S</p>
                                        <p>- zestaw solarny 130W</p>
                                        <p>- klimatyzator DOMETIC FJ2200</p>
                                        <p>- Dodatkowy akumulator w zabudowie mieszkalnej</p>
                                        <p>- Zewnętrzne szybkozłącze na prysznic</p>
                                        <p>- Zabezpieczenie drzwi FIAMMA Safe Door (3 szt.)</p>
                                        <p>- Telewizor ALPHATRONICS</p>
                                        <p>- Alarm gazowy</p>
                                        <p>- Przetwornica DOMETIC DSP612</p>
                                        <p>- Wąż gazowy SECUMOTION</p>
                                        <p>- Waga do butli</p>
                                        <p>- System SOG toalety</p>
                                        <p>- Stół i 4 fotele</p>
                                        <p>- Kliny poziomujące</p>
                                        <p>- Bagażnik na 4 rowery</p>
                                        <p>- Lodówka 120 Litrów wraz zamrażalnikiem</p>
                                        
                                    </div>
                        
                                </div>
                            </div>
                        </div>
                        <div className="col-4-12">
                            <div className="box colorbox2">
                                <div className="box-image"/>
                                <h2 className="box-title2">DOKUMENTY DO POBRANIA</h2>
                                <div className="box-content">
                                    <p>
                                        <a className="rental-files" href="/Regulamin wynajmu przyczep.pdf">Pobierz "Regulamin wynajmu przyczep"</a>
                                    </p>
                                    <p>
                                        <a className="rental-files" href="/Skrocona instrukcja obslugi przyczepy kempingowej.pdf">Pobierz "Skrócona instrukcja obsługi przyczepy kempingowej"</a>
                                    </p>
                                    <p>
                                        <a className="rental-files" href="/Umowa wynajmu przyczep.pdf">Pobierz "Umowa wynajmu przyczep"</a>
                                    </p>
                                    <p>
                                        <a className="rental-files" href="/CHECKLISTA.pdf">Pobierz "CHECK-LISTA"</a>
                                    </p>
                                    <p>
                                        <a className="rental-files" href="/Cennik.pdf">Pobierz "Cennik i wyposażenie"</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a className="buttonlink" href="#/Item4">
	                <button onClick={e => this.totopClick3(e)} className="btn-navigation two bottombutton">Do góry &nbsp; &#8593;</button>
                </a>
                <a className="buttonlink" href="#/Item3">
	                <button onClick={e => this.totopClick3(e)} className="btn-navigation two bottombuttonback">&#8592; &nbsp; Powrót &nbsp;</button>
                </a>
            </div>
        )
    }
}