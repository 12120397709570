import React from 'react';
import {ParallaxBanner} from 'react-scroll-parallax';
import Image1 from "../../assets/banner.jpeg"
import Imagebanner from "../../assets/mobile/Bannermobile.jpeg";

export default class Banner extends React.Component {
    render() {
        return(
            <>
            <div className="main-banner-container">
                
                            <ParallaxBanner
                                className="main-banner-parallax"
                                layers={[
                                    {image: Image1, amount: 0.1}
                                    ]}
                                style={{height: '82%', width: '100%'}}
                            >
                            </ParallaxBanner>
            </div>
            <div className="main-banner-container-mobile">
                
                            <ParallaxBanner
                                className="main-banner-parallax"
                                layers={[
                                    {image: Imagebanner, amount: 0.15}
                                    ]}
                                style={{height: '82%', width: '100%'}}
                            >
                            </ParallaxBanner>
            </div>
            
            </>
        )
    }
}