import React from 'react';
import {HashRouter as Router, Switch,Route} from "react-router-dom";
import Main from "./components/Main";
import Button from "./components/MobileButton";
import Navigation from "./components/Navigation";
import Partspage from "./components/Partspage";
import Rentalpage from "./components/Rentalpage";
import Salespage from "./components/Salespage";
import Winteringpage from "./components/Winteringpage";
import Aboutuspage from "./components/Aboutuspage";
import NotFound from "./components/NotFound";
import Renovationpage from "./components/Renovationpage";
import Item1 from "./components/Rentalitem1";
import Item2 from "./components/Rentalitem2";
import Item3 from "./components/Rentalitem3";
import Item4 from "./components/Rentalitem4";

/*import logo from "./assets/logo.png"*/

function App() {
    return (
        <div>
            <Router>

                <header className="page-header-wrapper">
                    <div className="page-header">
                    <a href="https://camperowcy.pl/" className="main-logo-wrapper">
                            <div className="main-logo"></div>
                        </a>
                        <div className="main-contact-wrapper">
                            <div className="main-email">
                                <a className="main-email-address" href="mailto:camperowcy@gmail.com">camperowcy@gmail.com</a>
                            </div>
                            <div className="main-phone">
                                <a className="main-phone-number" href="tel:+48509684222">+48 509 684 222</a>
                            </div>
                            <div className="socialIcons">
                                <a href="https://www.facebook.com/Camper.Owcy">
                                    <div className="facebook"></div>
                                </a>
                                <a href="https://www.instagram.com/camperowcy/?igshid=1xskhnskqqdxs">
                                    <div className="instagram" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="main-nav">
                        <Navigation/>
                        <Button />
                    </div>
                </header>
                <Switch>
                    <Route exact path="/" component={Main}/>
                    <Route path="/Rentalpage" component={Rentalpage}/>
                    <Route path="/Salespage" component={Salespage}/>
                    <Route path="/Winteringpage" component={Winteringpage}/>
                    <Route path="/Aboutuspage" component={Aboutuspage}/>
                    <Route path="/Renovationpage" component={Renovationpage}/>
                    <Route path="/Partspage" component={Partspage}/>
                    <Route path="/Item1" component={Item1}/>
                    <Route path="/Item2" component={Item2}/>
                    <Route path="/Item3" component={Item3}/>
                    <Route path="/Item4" component={Item4}/>
                    <Route component={NotFound}/>
                </Switch>
                <footer>
                <div className="footer-content">
                                        2021 CamperOwcy  &nbsp;&nbsp;&nbsp;&nbsp;DESIGN BY:&nbsp;&nbsp;
                                        <a className="link2" href="https://business2web.co.uk">business2web.co.uk</a>
                                        <a className="link2" href="https://stronydlabiznesu.pl">&nbsp;&nbsp; stronydlabiznesu.pl</a>
                                    </div>
                </footer>
                
            </Router>
            
        </div>
    );
}

export default App;
