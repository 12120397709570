import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';


export default class Rentalpage extends React.Component {
    totopClick2(e){
        window.scrollTo(0, 0)
    }
    render() {
        return(
            <>
        <ScrollAnimation duration='2' animateIn='bounceInRight'
            animateOut='bounceOutLeft'>
            <div className="rental-generic-container">
                
                <div className="rental-generic">
                    <div className="rental-generic-textpanel">
                        <div className="rental-generic-header">
                            <h1>Hobby KMF 545 De Luxe Edition</h1>
                        </div>
                            <div className="rental-generic-text">
                            
                             <ul className="rental-generic-list">
                                <li>Rocznik: 2021</li>
                                <li>DMC: 1600 kg</li>
                                <li>Cena: od 210 zł/doba</li>
                                <li>Liczba miejsc: 6</li>
                                <li>Długość całkowita: 7,42 m</li>
                             </ul>
                             <a className="buttonlink" href="#/Item1">
                                <button onClick={e => this.totopClick2(e)} className="btn-generic one">Szczegóły &nbsp; &#8594;</button>
                             </a>
                            </div>
                    </div>
                    <div className="rental-generic-photo oposit">
                        <div className="rental-image-1"></div>
                    </div>
                </div>
            </div>
        </ScrollAnimation>

        <ScrollAnimation duration='2' animateIn='bounceInLeft'
            animateOut='bounceOutRight'>
            <div className="rental-generic-container">
                
                <div className="rental-generic">
                    <div className="rental-generic-photo">
                        
                        <div className="rental-image-2"></div>
                        
                    </div>

                    <div className="rental-generic-textpanel">
                        <div className="rental-generic-header">
                            <h1>Knaus Sport 450 FU Silver Selection 2021</h1>
                        </div>
                        <div className="rental-generic-text">
                        
                            <ul className="rental-generic-list">
                                <li>Rocznik: 2021</li>
                                <li>DMC: 1300 kg</li>
                                <li>Cena: od 210 zł/doba</li>
                                <li>Liczba miejsc: 5</li>
                                <li>Długość całkowita: 6,96 m</li>
                            </ul>
                            <a className="buttonlink" href="#/Item2">
                             <button onClick={e => this.totopClick2(e)} className="btn-generic one">Szczegóły &nbsp; &nbsp; &#8594;</button>
                             </a>
                            </div>
                    </div>
                </div>
            </div>
            </ScrollAnimation>
        
            <ScrollAnimation duration='2' animateIn='bounceInRight'
            animateOut='bounceOutLeft'>
            <div className="rental-generic-container">
                
                <div className="rental-generic">
                    <div className="rental-generic-textpanel">
                        <div className="rental-generic-header">
                            <h1>Burstner Averso 470TS</h1>
                        </div>
                            <div className="rental-generic-text">
                            
                             <ul className="rental-generic-list">
                                <li>Rocznik: 2011</li>
                                <li>DMC: 1250 kg</li>
                                <li>Cena: od 140 zł/doba</li>
                                <li>Liczba miejsc: 4</li>
                                <li>Długość całkowita: 7,16 m</li>
                             </ul>
                             <a className="buttonlink" href="#/Item3">
                                <button onClick={e => this.totopClick2(e)} className="btn-generic one">Szczegóły &nbsp; &#8594;</button>
                             </a>
                            </div>
                    </div>
                    <div className="rental-generic-photo oposit">
                        <div className="rental-image-3"></div>
                    </div>
                </div>
            </div>
        </ScrollAnimation>

        <ScrollAnimation duration='2' animateIn='bounceInLeft'
            animateOut='bounceOutRight'>
            <div className="rental-generic-container">
                
                <div className="rental-generic">
                    <div className="rental-generic-photo">
                        
                        <div className="rental-image-4"></div>
                        
                    </div>

                    <div className="rental-generic-textpanel">
                        <div className="rental-generic-header">
                            <h1>FORD CHAUSSON 627GA VIP PREMIUM - 2021</h1>
                        </div>
                        <div className="rental-generic-text">
                        
                            <ul className="rental-generic-list">
                                <li>Rocznik: 2021</li>
                                <li>Silnik: 170 KM Diesel 2.0 / DMC: 3500 kg (prawo jazdy kat. B)</li>
                                <li>Cena: od 550 zł/doba</li>
                                <li>Liczba miejsc: 5</li>
                                <li>Długość całkowita: 6,99 m</li>
                            </ul>
                            <a className="buttonlink" href="#/Item4">
                             <button onClick={e => this.totopClick2(e)} className="btn-generic one">Szczegóły &nbsp; &nbsp; &#8594;</button>
                             </a>
                            </div>
                    </div>
                </div>
            </div>
            </ScrollAnimation>
        </>
        )
    }
}