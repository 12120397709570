import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

export default class Renovation extends React.Component {
    render() {
        return(
            <ScrollAnimation duration='2' animateIn='bounceInRight'
            animateOut='bounceOutLeft'>
            <div className="main-generic-container">
                
                <div className="main-generic">
                    <div className="main-generic-textpanel">
                        <div className="main-generic-header">
                            <h1>Renowacja</h1>
                        </div>
                        <div className="main-generic-text">
                            <p>Nawet starsze przyczepy kempingowe, mogą służyć wiele lat.
                            Dzięki temu, że nie mają silnika, nie tracą wiele na wartości.
                            Bardzo satysfakcjonujące jest dawanie im drugiego życia!</p>
                            <ul className="main-generic-list">
                                <li>Nowy wyglad i styl</li>
                                <li>Szczelność</li>
                                <li>Wymiany tkanin i materacy</li>
                                <li>Nowe oświetlenie</li>
                            </ul>
                            <a className="buttonlink" href="#/Renovationpage">
                                <button className="btn-generic one">Zobacz realizacje &nbsp; &#8594;</button>
                            </a>
                            </div>
                    </div>
                    <div className="main-generic-photo">
                        <div className="main-renovation-image"></div>
                    </div>
                </div>
            </div>
            </ScrollAnimation>
        )
    }
}