import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

export default class Partspage extends React.Component {
    render() {
        return(
            <ScrollAnimation duration='2' animateIn='bounceInLeft'
            animateOut='bounceOutRight'>
            <div className="main-generic-container">
                
                <div className="main-generic">
                    <div className="main-generic-photo">
                    <div className="main-parts-image"></div>
                    </div>

                    <div className="main-generic-textpanel">
                        <div className="main-generic-header">
                            <h1>Części i akcesoria</h1>
                        </div>
                        <div className="main-generic-text">
                            <p>Części zamienne oraz akcesoria do przyczep, zarówno nowe jak i używane.</p>
                            <p>Jeśli nie mamy ich w ofercie, to pomożemy wam je zdobyć.</p>
                            <ul className="main-generic-list">
                                <li>Części zamienne</li>
                                <li>Okna i uszczelki</li>
                                <li>Środki czystości</li>
                                <li>Akcesoria na kempingi</li>
                            </ul>
                            {/*<button className="btn-rental one">Sprawdź ofertę &nbsp; &nbsp; &#8594;</button>*/}
                            </div>
                    </div>
                </div>
            </div>
            </ScrollAnimation>
        )
    }
}