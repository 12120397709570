import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

export default class Rental extends React.Component {
    totopClick(e){
        window.scrollTo(0, 0)
    }
    render() {
        return(
            <ScrollAnimation duration='2' animateIn='bounceInLeft'
            animateOut='bounceOutRight'>
            <div className="main-rental-container">
                
                <div className="main-generic">
                    <div className="main-generic-photo">
                        
                        <div className="main-rental-image"></div>
                        
                    </div>

                    <div className="main-generic-textpanel">
                        <div className="main-generic-header">
                            <h1>Wynajem</h1>
                        </div>
                        <div className="main-generic-text">
                            <p>Jeśli chcesz spróbować nowego, bezpiecznego i
                            niezależnego stylu podróżowania, albo już jesteś
                            jego miłośnikiem, ale chcesz sprawdzić inny model,
                            zapraszamy do skorzystania z oferty wynajmu krótko
                            lub długookresowego.</p>
                            <ul className="main-generic-list">
                                <li>Specjalne ceny</li>
                                <li>Miła obsługa i szkolenie</li>
                                <li>Nowe wyposażenie</li>
                                <li>Klimatyzacja</li>
                                <li>Czystość i bezpieczeństwo</li>
                            </ul>
                            <a className="buttonlink" href="#/Rentalpage">
                             <button onClick={e => this.totopClick(e)} className="btn-generic one">Sprawdź ofertę &nbsp; &nbsp; &#8594;</button>
                             </a>
                            </div>
                    </div>
                </div>
            </div>
            </ScrollAnimation>
        )
    }
}