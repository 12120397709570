import React from 'react';
import ImageGallery from 'react-image-gallery';

import kn1 from "../assets/1k.jpg";
import kn2 from "../assets/2k.jpg";
import kn3 from "../assets/3k.jpg";
import kn4 from "../assets/4k.jpg";
import kn5 from "../assets/5k.jpg";
import kn6 from "../assets/6k.jpg";
import kn7 from "../assets/7k.jpg";
import kn8 from "../assets/8k.jpg";
import kn9 from "../assets/9k.jpg";
import kn10 from "../assets/10k.jpg";
import kn11 from "../assets/11k.jpg";



import knm1 from "../assets/mobile/1km.jpg";
import knm2 from "../assets/mobile/2km.jpg";
import knm3 from "../assets/mobile/3km.jpg";
import knm4 from "../assets/mobile/4km.jpg";
import knm5 from "../assets/mobile/5km.jpg";
import knm6 from "../assets/mobile/6km.jpg";

const imagesrental2 = [
    {
        original: kn1,
        thumbnail: kn1,
    },
    {
        original: kn2,
        thumbnail: kn2,
    },
    {
        original: kn3,
        thumbnail: kn3,
    },
    {
        original: kn4,
        thumbnail: kn4,
    },  
    {
        original: kn5,
        thumbnail: kn5,
    },
    {
        original: kn6,
        thumbnail: kn6,
    },
    {
        original: kn7,
        thumbnail: kn7,
    },
    {
        original: kn8,
        thumbnail: kn8,
    },
    {
        original: kn9,
        thumbnail: kn9,
    },
    {
        original: kn10,
        thumbnail: kn10,
    },
    {
        original: kn11,
        thumbnail: kn11,
    },
];

const imagesrental2m = [
    {
        original: knm1,
        thumbnail: knm1,
    },
    {
        original: knm2,
        thumbnail: knm2,
    },
    {
        original: knm3,
        thumbnail: knm3,
    },
    {
        original: knm4,
        thumbnail: knm4,
    },
    {
        original: knm5,
        thumbnail: knm5,
    },
    {
        original: knm6,
        thumbnail: knm6,
    },
];

const properties = {
    thumbnailPosition: "bottom",
    autoPlay: false,
};


export default class Item2 extends React.Component {
    totopClick3(e){
        window.scrollTo(0, 0)
    }
    render() {
        return(
            <div className="page-main">
                <div className="container">    
                    <div className="row">
                        <div className="col-8-12">
                            <div className="box">
                                <div className="box-image"/>
                                <h1 className="box-title">Knaus Sport 450 FU Silver Selection 2021</h1>
                                <div className="box-content-gallery">
                                    <div className="image-gallery-rental-desktop">
                                        <ImageGallery {...properties} items={imagesrental2} />
                                    </div>
                                    <div className="image-gallery-rental-mobile">
                                        <ImageGallery {...properties} items={imagesrental2m} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4-12">
                            <div className="box colorbox">
                                <div className="box-image"/>
                                <h2 className="box-title1">CENNIK</h2>
                                    <div className="box-content1">
                                        <p className="rental-text-paragraf">Koszt za dobę:</p>
                                        <p>Sezon niski: 1 październik – 23 kwiecień (minimalny wynajem: 3 dni) - 150 zł</p>
                                        <p>Sezon średni: 10 maj – 11 czerwiec, 13 wrzesień – 30 wrzesień (minimalny wynajem: 5 dni) - 200 zł</p>
                                        <p>Sezon wysoki: 24 kwiecień – 9 maj, 12 czerwiec – 12 wrzesień (minimalny wynajem: 7 dni) - 260 zł</p>
                                        <p>Możliwość dowiezienia przyczepy we wskazane miejsce! (Koszt wyceniany indywidualnie)</p>
                                        <p className="rental-text-paragraf">Rabaty:</p>
                                        <p>Wynajem powyżej 14 dni – opłata serwisowa gratis.</p>
                                        <p>Wynajem powyżej 21 dni – rabat 5%</p>
                                        <p>Opłata serwisowa 250 zł - obejmuje mycie przyczepy od wewnątrz i z zewnątrz, pełną butlę z gazem, kontrolę i serwis urządzeń oraz pakiet toaletowy na około 12 dni toalety: kaseta z WC jest czyszczona i uzupełniona o chemię na ok. 4 doby, wyposażona dodatkowo znajdują się: 3 rolki papieru toaletowego – rozpuszczalnego, koncentrat płynu do spłuczki, 3 kostki rozpuszczalne i zapachowe do kasety WC.</p>
                                        <p>Kaucja zwrotna: 2500 PLN</p>
                                        <p>Przy rezerwacji pobieramy 30% kosztu wynajmu.</p>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8-12">
                            <div className="box1">
                                <h1 className="box-title">OPIS</h1>
                                <div className="box-content">  
                                    <div className="rental-text">
                                        <p className="rental-text-paragraf">Marka i model: Knaus Sport 450 FU Silver Selection 2021</p>
                                        <p>Nowa, 4 - osobowa przyczepa Knaus Sport 450 FU Silver Selection 2021.</p>
                                        <p>Lekka przyczepa o bardzo wygodnym układzie wnętrza.</p>
                                        <p>Miejsca do spania dla osób dorosłych: 4</p>
                                        <p  className="rental-text-paragraf">Dopuszczalna masa całkowita: 1.300 kg</p>
                                        <p>Ładowność: 130 kg</p>
                                        <p>Długość całkowita: 6.960 mm, długość przyczepy: 4.700 mm</p>
                                        <p>Wysokość całkowita: 2.570 mm, wysokość wnętrza: 1.960 mm</p>
                                        <p>Wysokość całkowita: 2.570 mm, wysokość wnętrza: 1.960 mm</p>
                                        <h2 className="rental-text-paragraf">Wyposażenie:</h2>
                                        <p>- Zbiornik świeżej wody i przyłącze wody z sieci miejskiej!</p>
                                        <p>- Rozbudowany prysznic</p>
                                        <p>- Zewnętrzne przyłącze gazu</p>
                                        <p>- Markiza dachowa THULE, szerokość 350 cm, wysięg 250 cm</p>
                                        <p>- Bagażnik rowerowy</p>
                                        <p>- Stolik i krzesła turystyczne zewnętrzne</p>
                                        <p>- Bojler elektryczny TRUMA,</p>
                                        <p>- AL-KO AAA System hamowania PREMIUM</p>
                                        <p>- Zbiornik na wodę brudną 25l na kółkach</p>
                                        <p>- oświetlenie Ambiente</p>
                                        <p>- Zewnetrzne gniazdo 230V i antenowe</p>
                                        <p>- Dwa dodatkowe spoty LED w kuchni</p>
                                        <p>- Otwierane okno w toalecie z moskitierami</p>
                                        <p>- Moskitiera drzwiowa na całe drzwi</p>
                                        <p>- Drzwi wejściowe KNAUS STYLE PLUS z Panoramiczne okno dachowe HEKI II</p>
                                        <p>- Czujnik dymu</p>
                                        <p>- System naprawczy do kół</p>
                                        <p>- Pakiet gniazd 230v i USB</p>
                                        <p>- System filtracji wody BWT</p>
                                        <p>- Apteczka pierwszej pomocy MedKit</p>
                                        <p className="rental-text-paragraf">Część sypialna</p>
                                        <p>- łóżko przód: 200x140, łóżko tył: 200x150</p>
                                        <p>- Wykładzina podłogowa PCV – imitacja parkietu</p>
                                        <p>- Wysokiej jakosci materace piankowe, Evo Pore HRC</p>
                                        <p>- Tekstylne zasłony oddzielajace pomieszczenia</p>
                                        <p>- Spoty świetlne, indywidualnie regulowane w sypialni i salonie</p>
                                        <p>- Kanapa z wysokiej jakości siedziskami, konstrukcja siedzeń wentylowana</p>
                                        <p>- Tapicerka wykonana z materiałów odpornych na zużycie z zamkami błyskawicznymi, ściągana</p>
                                       <div className="rental-text-paragraf-small">
                                        <p className="rental-text-paragraf-small">Projekt współfinansowany ze środków Unii Europejskiej w ramach Regionalnego Programu Operacyjnego Województwa Kujawsko – Pomorskiego na lata 2014 – 2020,</p>
                                        <p className="rental-text-paragraf-small">w ramach projektu grantowego FUNDUSZ WSPARCIA INWESTYCYJNEGO nr RPKP.01.06.02-04-0001/20</p>
                                       </div>
                                        
                                    </div>
                        
                                </div>
                            </div>
                        </div>
                        <div className="col-4-12">
                            <div className="box colorbox2">
                                <div className="box-image"/>
                                <h2 className="box-title2">DOKUMENTY DO POBRANIA</h2>
                                <div className="box-content">
                                    <p>
                                        <a className="rental-files" href="/Regulamin wynajmu przyczep.pdf">Pobierz "Regulamin wynajmu przyczep"</a>
                                    </p>
                                    <p>
                                        <a className="rental-files" href="/Skrocona instrukcja obslugi przyczepy kempingowej.pdf">Pobierz "Skrócona instrukcja obsługi przyczepy kempingowej"</a>
                                    </p>
                                    <p>
                                        <a className="rental-files" href="/Umowa wynajmu przyczep.pdf">Pobierz "Umowa wynajmu przyczep"</a>
                                    </p>
                                    <p>
                                        <a className="rental-files" href="/CHECKLISTA.pdf">Pobierz "CHECK-LISTA"</a>
                                    </p>
                                    <p>
                                        <a className="rental-files" href="/Cennik.pdf">Pobierz "Cennik i wyposażenie"</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a className="buttonlink" href="#/Item3">
	                <button onClick={e => this.totopClick3(e)} className="btn-navigation two bottombutton">Następna &nbsp; &#8594;</button>
                </a>
                <a className="buttonlink" href="#/Item1">
	                <button onClick={e => this.totopClick3(e)} className="btn-navigation two bottombuttonback">&#8592; &nbsp; Powrót &nbsp;</button>
                </a>
            </div>
        )
    }
}