import React from 'react';
import ImageGallery from 'react-image-gallery';

import hb1 from "../assets/1h.jpg";
import hb2 from "../assets/2h.jpg";
import hb3 from "../assets/3h.jpg";
import hb4 from "../assets/4h.jpg";
import hb5 from "../assets/5h.jpg";
import hb6 from "../assets/6h.jpg";
import hb7 from "../assets/7h.jpg";
import hb8 from "../assets/8h.jpg";
import hb9 from "../assets/9h.jpg";
import hb10 from "../assets/10h.jpg";
import hb11 from "../assets/11h.jpg";
import hb12 from "../assets/12h.jpg";
import hb13 from "../assets/13h.jpg";
import hb14 from "../assets/14h.jpg";

import hba1 from "../assets/mobile/1hm.jpg";
import hba2 from "../assets/mobile/2hm.jpg";
import hba3 from "../assets/mobile/3hm.jpg";
import hba4 from "../assets/mobile/4hm.jpg";
import hba5 from "../assets/mobile/5hm.jpg";
import hba6 from "../assets/mobile/6hm.jpg";
import hba7 from "../assets/mobile/7hm.jpg";
import hba8 from "../assets/mobile/8hm.jpg";
import hba9 from "../assets/mobile/9hm.jpg";
import hba10 from "../assets/mobile/10hm.jpg";

const imagesrental1 = [
    {
        original: hb1,
        thumbnail: hb1,
    },
    {
        original: hb2,
        thumbnail: hb2,
    },
    {
        original: hb3,
        thumbnail: hb3,
    },
    {
        original: hb4,
        thumbnail: hb4,
    },
    {
        original: hb5,
        thumbnail: hb5,
    },
    {
        original: hb6,
        thumbnail: hb6,
    },
    {
        original: hb7,
        thumbnail: hb7,
    },
    {
        original: hb8,
        thumbnail: hb8,
    },
    {
        original: hb9,
        thumbnail: hb9,
    },
    {
        original: hb10,
        thumbnail: hb10,
    },
    {
        original: hb11,
        thumbnail: hb11,
    },
    {
        original: hb12,
        thumbnail: hb12,
    },
    {
        original: hb13,
        thumbnail: hb13,
    },
    {
        original: hb14,
        thumbnail: hb14,
    }  
];

const imagesrental1m = [
    {
        original: hba1,
        thumbnail: hba1,
    },
    {
        original: hba2,
        thumbnail: hba2,
    },
    {
        original: hba3,
        thumbnail: hba3,
    },
    {
        original: hba4,
        thumbnail: hba4,
    },
    {
        original: hba5,
        thumbnail: hba5,
    },
    {
        original: hba6,
        thumbnail: hba6,
    },
    {
        original: hba7,
        thumbnail: hba7,
    },
    {
        original: hba8,
        thumbnail: hba8,
    },
    {
        original: hba9,
        thumbnail: hba9,
    },
    {
        original: hba10,
        thumbnail: hba10,
    },
];

const properties = {
    thumbnailPosition: "bottom",
    autoPlay: false,
};


export default class Item1 extends React.Component {
    totopClick3(e){
        window.scrollTo(0, 0)
    }

    render() {
        return(
            <div className="page-main">
                <div className="container">    
                    <div className="row">
                        <div className="col-8-12">
                            <div className="box">
                                <div className="box-image"/>
                                <h1 className="box-title">Hobby 545 KMF De Luxe Edition</h1>
                                <div className="box-content-gallery">
                                    <div className="image-gallery-rental-desktop">
                                        <ImageGallery {...properties} items={imagesrental1} />
                                    </div>
                                    <div className="image-gallery-rental-mobile">
                                        <ImageGallery {...properties} items={imagesrental1m} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4-12">
                            <div className="box colorbox">
                                <div className="box-image"/>
                                <h2 className="box-title1">CENNIK</h2>
                                    <div className="box-content1">
                                        <p className="rental-text-paragraf">Koszt za dobę:</p>
                                        <p>Sezon niski: 1 październik – 23 kwiecień (minimalny wynajem: 3 dni) - 210 zł</p>
                                        <p>Sezon średni: 10 maj – 11 czerwiec, 13 wrzesień – 30 wrzesień (minimalny wynajem: 5 dni) - 260 zł</p>
                                        <p>Sezon wysoki: 24 kwiecień – 9 maj, 12 czerwiec – 12 wrzesień (minimalny wynajem: 7 dni) - 310 zł</p>
                                        <p>Możliwość dowiezienia przyczepy we wskazane miejsce! (Koszt wyceniany indywidualnie)</p>
                                        <p className="rental-text-paragraf">Rabaty:</p>
                                        <p>Wynajem powyżej 14 dni – opłata serwisowa gratis.</p>
                                        <p>Wynajem powyżej 21 dni – rabat 5%</p>
                                        <p>Opłata serwisowa 250 zł - obejmuje mycie przyczepy od wewnątrz i z zewnątrz, pełną butlę z gazem, kontrolę i serwis urządzeń oraz pakiet toaletowy na około 12 dni toalety: kaseta z WC jest czyszczona i uzupełniona o chemię na ok. 4 doby, wyposażona dodatkowo znajdują się: 3 rolki papieru toaletowego – rozpuszczalnego, koncentrat płynu do spłuczki, 3 kostki rozpuszczalne i zapachowe do kasety WC.</p>
                                        <p>Kaucja zwrotna: 3000 PLN</p>
                                        <p>Przy rezerwacji pobieramy 30% kosztu wynajmu.</p>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8-12">
                            <div className="box1">
                                <h1 className="box-title">OPIS</h1>
                                <div className="box-content">  
                                    <div className="rental-text">
                                        <p className="rental-text-paragraf">Marka i model: Hobby 545 KMF De Luxe Edition</p>
                                        <p>Luksusowa, klimatyzowana, zupełnie nowa, 5/6 - osobowa przyczepa Hobby 545 KMF.</p>
                                        <p>Markiza, szerokie drzwi wejściowe, moskitiery we wszystkich oknach,</p>
                                        <p>bagażnik na dwa rowery i bardzo duża lodówka o pojemności do 150 litrów!</p>
                                        <p>Wygodny układ sprawia, że przy 4 osobach nie trzeba rozkładać siedziska jadalni!</p>
                                        <p>Miejsca do spania dla osób dorosłych: 3/4</p>
                                        <p>Miejsca do spania dla dzieci: 2/3</p>
                                        <p className="rental-text-paragraf">Dopuszczalna masa całkowita: 1.600 kg</p>
                                        <p>Ładowność: 177 kg</p>
                                        <p>Długość całkowita: 7.422 mm, długość przyczepy: 6.256 mm</p>
                                        <p>Wysokość całkowita: 2.636 mm, wysokość wnętrza: 1.950 mm</p>
                                        <p>Szerokość całkowita: 2.500 mm, szerokość wnętrza: 2.367 mm</p>
                                        <h2 className="rental-text-paragraf">Wyposażenie:</h2>
                                        <p>- Klimatyzacja Dometic 2200 z funkcją grzania</p>
                                        <p>- Zbiornik świeżej wody i przyłącze wody z sieci miejskiej!</p>
                                        <p>- Oświetlenie LED</p>
                                        <p>- Zestaw Autark-Set (środek, lewa strona)</p>
                                        <p>- Zewnętrzne przyłącze gazu (środek, prawa strona)</p>
                                        <p>- Markiza dachowa THULE OMNISTOR 6300, szerokość 400 cm, wysięg 250 cm</p>
                                        <p>- Bagażnik rowerowy THULE montowany na dyszlu, na 2 rowery, ładowność 60 kg</p>
                                        <p>- Stolik i krzesła turystyczne zewnętrzne</p>
                                        <p>- Bojler elektryczny TRUMA,</p>
                                        <p>- Podwójne gniazdo do ładowania USB oraz lampki przy łóżkach dziecięcych z gniazdem USB do ładowania</p>
                                        <p className="rental-text-paragraf">Część sypialna</p>
                                        <p>- Komfortowe łóżko podwójne - przy ścianie (długość x szerokość): 2.000 x 1.380 / 1.090 mm, z materacem z zimnej pianki, 7-strefowym</p>
                                        <p>- Łóżko dziecięce, 2-piętrowe z dwoma oknami, z zabezpieczeniem przed wypadnięciem i drabinką (długość x szerokość): 2 x 1.850 x 700 mm</p>
                                        <p>- Możliwość przebudowania siedziska w łóżko (długość x szerokość) 1.976 x 1.130 mm.</p>
                                        
                                    </div>
                        
                                </div>
                            </div>
                        </div>
                        <div className="col-4-12">
                            <div className="box colorbox2">
                                <div className="box-image"/>
                                <h2 className="box-title2">DOKUMENTY DO POBRANIA</h2>
                                <div className="box-content">
                                    <p>
                                        <a className="rental-files" href="/Regulamin wynajmu przyczep.pdf">Pobierz "Regulamin wynajmu przyczep"</a>
                                    </p>
                                    <p>
                                        <a className="rental-files" href="/Skrocona instrukcja obslugi przyczepy kempingowej.pdf">Pobierz "Skrócona instrukcja obsługi przyczepy kempingowej"</a>
                                    </p>
                                    <p>
                                        <a className="rental-files" href="/Umowa wynajmu przyczep.pdf">Pobierz "Umowa wynajmu przyczep"</a>
                                    </p>
                                    <p>
                                        <a className="rental-files" href="/CHECKLISTA.pdf">Pobierz "CHECKLISTA"</a>
                                    </p>
                                    <p>
                                        <a className="rental-files" href="/Cennik.pdf">Pobierz "Cennik i wyposażenie"</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a className="buttonlink" href="#/Item2">
	                <button onClick={e => this.totopClick3(e)} className="btn-navigation two bottombuttonback">Następna &nbsp; &#8594;</button>
                </a>
            </div>
        )
    }
}